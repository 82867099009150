<template>
  <div class="is-flex is-align-content-center is-justify-content-center">
    <div class="mr-1">
      <b-button
          v-if="!hideBackBtn"
          icon-left="chevron-left"
          outlined
          @click="$emit('back')"
          :disabled="loading || !backable"
          :loading="loading"
      >{{ backText }}
      </b-button>
    </div>
    <div class="ml-1">
      <b-button
          icon-right="chevron-right"
          native-type="submit"
          type="is-primary"
          :disabled="!nextable"
          :loading="loading"
      >{{ nextText }}
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FormNav',
  props: {
    loading: {
      type: Boolean
    },
    backable: {
      type: Boolean,
    },
    nextable: {
      type: Boolean,
    },
    hideBackBtn: {
      type: Boolean,
    },
    backText: {
      type: String,
      default: 'Back',
    },
    nextText: {
      type: String,
      default: 'Next',
    },
  },
}
</script>
