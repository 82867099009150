export const SurveyEndMixin = {
  computed: {
    endType () {
      return this.survey?.end_type
    },
  },
  watch: {
    isFinished (value) {
      if (value && this.endType === 'completeurl') this.redirect()
    },
  }
}
