<template>
  <div class="survey">
    <Policy v-if="showPolicy" :lang="lang" @confirmed="showPolicy=false"></Policy>
    <Reward v-else-if="isFinished && endType === 'defaultrewards'" :lang="lang" :sid="survey.sid"
            :rid="response._id"></Reward>
    <template v-else-if="!error && survey">
      <template v-if="!isFinished">
        <div class="survey-progress">
          <b-progress :value="current" :max="max" size="is-small" show-value type="is-dark-green">
            <span v-if="max">{{ current }} / {{ max }}</span>
          </b-progress>
        </div>
        <section class="section">
          <ValidationObserver tag="div" class="container" v-slot="{ handleSubmit, invalid }">
            <form id="survey" @submit.prevent="handleSubmit(onSubmit)">
              <template v-if="page">
                <div v-for="question in questions" :key="question.qid" class="mb-4">
                  <div class="columns is-centered is-vcentered">
                    <component
                        :is="`Q${question.type}`"
                        :question="question"
                        :lang="lang"
                        :value="mergedChanges"
                        :loading="loading"
                        :show-question-code="showQuestionCode"
                        @input="onChange"
                    ></component>
                  </div>
                </div>
              </template>
              <FormNav
                  class="form-nav my-5 has-text-centered"
                  @back="onBack"
                  :backable="current > 0"
                  :nextable="!(current === max) || invalid"
                  :hide-back-btn="!survey.is_backable"
                  :back-text="$t('btn.previous')"
                  :next-text="$t(`btn.${isLastPage ? 'submit' : 'next'}`)"
                  :loading="loading"
              ></FormNav>
            </form>
          </ValidationObserver>
        </section>
      </template>
      <section v-if="isFinished" class="hero is-fullheight">
        <div class="hero-body">
          <div class="container">
            <div class="columns is-centered is-vcentered">
              <div class="column is-8">
                <div v-if="!endType" class="has-text-centered">
                  <h1 class="title">
                    {{ $t('endMessage') }}
                  </h1>
                </div>
                <SandboxHtml v-if="endType === 'message'" :html="translations.end_msg"></SandboxHtml>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <ErrorMessage :error="error"></ErrorMessage>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { BACK, NEXT, START_SURVEY, UPDATE_CHANGES } from '../store'
import FormNav from '@/components/FormNav'
import { QuestionTypeComponentsMixin } from '@/mixins/question-type-components'
import { SurveyMetaMixin } from '@/mixins/survey-meta'
import { LocaleSwitchingMixin } from '@/mixins/locale-switching'
import { SurveyEndMixin } from '@/mixins/survey-end'
import { ScrollToTopMixin } from '@/mixins/scroll-to-top'
import { ErrorHandlerMixin } from '@/mixins/error-handler'

export default {
  name: 'Survey',
  mixins: [
    QuestionTypeComponentsMixin,
    SurveyMetaMixin,
    LocaleSwitchingMixin,
    SurveyEndMixin,
    ScrollToTopMixin,
    ErrorHandlerMixin,
  ],
  components: {
    FormNav,
  },
  data () {
    return {
      error: null,
      changes: {},
      showPolicy: false,
      showReward: false,
    }
  },
  computed: {
    loading () {
      return this.$store.getters.loading(this.code)
    },
    survey () {
      return this.$store.getters.survey(this.code)
    },
    page () {
      return this.$store.getters.page(this.code)
    },
    questions () {
      return this.$store.getters.questions(this.code)
    },
    response () {
      return this.$store.getters.response(this.code)
    },
    result () {
      return this.$store.getters.result(this.code)
    },
    currentState () {
      return this.$store.getters.currentState(this.code)
    },
    lang () {
      return this.response?.language
    },
    current () {
      return this.currentState?.pidx
    },
    mergedChanges () {
      return { ...this.result, ...this.changes }
    },
    isFinished () {
      return !!this.response?.submitted_at
    },
  },
  watch: {
    isFinished () {
      if (this.endType === 'completeurl') this.redirect()
    }
  },
  methods: {
    ...mapActions([START_SURVEY, BACK, NEXT]),
    ...mapMutations([UPDATE_CHANGES]),
    async init () {
      const loadingComponent = this.$buefy.loading.open()
      this.error = null
      try {
        const {
          params: { code },
          query
        } = this.$route
        await this[START_SURVEY]({ code, ...query })

        this.showPolicy = this.survey.show_policy

      } catch (err) {
        this.errorHandler(err)
      } finally {
        loadingComponent.close()
      }
    },
    async onSubmit () {
      this.error = null
      try {
        await this[NEXT]({
          code: this.code,
          data: { response_id: this.response._id, result: this.changes }
        })
        let changes = this.currentState?.changes || {};
        for (const [key, value] of Object.entries(changes)) {
          const exist = this.questions.find((question) => question.id === key.split('_')?.[0].toLowerCase())
          changes[key] = exist ? value : "";
        }
        this.changes = changes
        this.scrollToTop()

        if (this.page && !this.questions.length) await this.onSubmit()
      } catch (err) {
        this.errorHandler(err)
      }
    },
    async onBack () {
      this.error = null
      try {
        await this[BACK]({
          code: this.code,
          data: { response_id: this.response._id, result: this.changes }
        })

        if (this.page && !this.questions.length) await this.onBack()
      } catch (err) {
        this.errorHandler(err)
      }
    },
    onChange (data) {
      this.changes = { ...this.changes, ...data }
      this[UPDATE_CHANGES]({ code: this.code, changes: this.changes })
    },
    redirect () {
      window.location.href = this.translations?.completed_url
    }
  },
  async created () {
    await this.init()
  }
}
</script>
